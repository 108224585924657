.form-control{
	width: 100%;
	margin-bottom: 5px;
	font-size: 14px;
	border-radius: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	padding: 8px 10px;
	border: 1px solid #e1e1e1;
}
.form-control:focus{
	box-shadow: none;
	outline: none;
}
.btn.disabled,
.btn:disabled{
	opacity: 1;
}
.contact-form-area {
	background: #fff;
	box-shadow: 0 0 30px #ededed;
	padding: 60px 40px;

	h2{
		font-size: 18px;
		text-transform: uppercase;
	}
}
.footer-right-contact {
	padding: 4px;
	border: 1px solid #e1e1e1;
	margin-top: 30px;

	.single-contact {
		margin: 10px 22px;
		padding: 3px 55px;
		position: relative;
		color: #666;
		p{
			margin-bottom: 5px;
			a{
				color: #666;
			}
		}
	}
	.contact-icon{
		background: $preset;
		color: #fff;
		border-radius: 50%;
		font-size: 20px;
		height: 40px;
		left: 0;
		padding-top: 8px;
		position: absolute;
		text-align: center;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 40px;
	}
}
#contact{
	position: relative;
	overflow: hidden;
	.contact-block{

	}
	#contactForm{
		margin-top: 30px;
	}
}
.text-danger{
	font-size: 14px;
	margin-top: 10px;
}
.list-unstyled {
	li{
		color: #d9534f;
	}
}
.with-errors {
	color: #d9534f;
	padding: 5px 0;
	margin-bottom: 10px;
}

iframe {
	border:0;
	height: 450px;
	width: 100%;
}

textarea {
	resize: none;
}