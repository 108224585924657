.navbar{
  padding: 0;
  background-color: $white;

  li.active a.nav-link {
    color: $preset;
  }
}

.navbar-toggler {
  &,
  &:focus {
    box-shadow: 0 0 0 1px $preset;
  }
}

.navbar-toggler-icon {
  background-image: url(
  "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 81, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-expand-lg {
  .navbar-nav {
    .nav-link{
      color: #333;
      font-weight: 700;
      padding: 0 17px;
      margin-top: 15px;
      margin-bottom: 15px;
      line-height: 40px;
      text-transform: uppercase;
      cursor: pointer;
      background: transparent;
      transition: all 0.3s ease-in-out;

      i{
        font-size: 28px;
        color: $preset;
        vertical-align: middle;
        transition: all 0.3s ease-in-out;
        &:hover{
          color: #E14902;
        }
      }
    }

    li {
      a:hover,
      .active > a,
      a:focus {
        color: $preset;
        outline: none;
      }
    }
  }
}

.fadeInUpMenu {
  -webkit-animation-name: fadeInUpMenu;
  animation-name: fadeInUpMenu;
}

@media screen and (max-width: 768px) {
  .navbar-header{
    width: 100%;
  }
}
