//All Preset color variables are here
$primary-font: 'Source Sans Pro', sans-serif;
$regular: 400;
$medium: 500;
$bold: 700;

// Red
$red: #FF5100;

//Current Preset Declareation
$preset:  $red;

$white: #FFFFFF;
$black: #000000;