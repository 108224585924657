@import "vars";

@import '~bootstrap/scss/bootstrap.scss';
@import '~simple-line-icons/scss/simple-line-icons.scss';
@import '~@fortawesome/fontawesome-free/css/all.css';

@import "global";
@import "about";
@import "navbar";
@import "hero-area";
@import "portfolio";
@import "service";
@import "contact";
@import "footer";
@import "responsive";
