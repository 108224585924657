/* Services Item */
.services{
	background: #F0F0F0;
}
.services-item{
	background: #fff;
	border-radius: 4px;
	padding: 48px 20px;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
	text-align: center;
	transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
	.icon{
		i{
		    font-size: 42px;
		    margin-bottom: 15px;
		    text-align: center;
		    width: 48px;
		   	display: block;
		   	margin: 0 auto;
		    height: 48px;
		    color: $preset;
		}
	}
	.services-content{
		h3{
			margin-top: 10px;
			margin-bottom: 10px;
			font-size: 18px;
			text-transform: uppercase;
			color: #666;
			&:hover{
				color: $preset;
			}
		}
		p{
			line-height: 22px;
		}
	}
}
.services-item:hover{
	box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
}




