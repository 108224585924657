/* ==========================================================================
3. Hero Area
========================================================================== */
.hero-area-bg{
	background: url(../images/hero-area.jpg) no-repeat;
	background-size: cover;
}
#hero-area{
	color: $white;
	overflow: hidden;
	position: relative;
	.overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;			
		left: 0px;
		background: #0c0808;
		opacity: 0.6;
	}
	.contents{
		padding: 175px 0 120px;
		position: relative;
		h5{
			font-size: 50px;
		}
		.head-title{	
			color: $white;
		    font-size: 80px;
			letter-spacing: 10px;
		    text-transform: uppercase;
		    font-weight: $bold;
		}
		p{
			font-size: 30px;
			color: $white;
			font-weight: $regular;
			line-height: 30px;
			margin-bottom: 25px;
		}
		.btn{
			margin: 25px 0px; 
			text-transform: uppercase;
		}
	}
	.banner_bottom_btn{
		margin-top: 40px;
		i{
			color: $white;
			font-size: 48px;
			-webkit-transition: all 0.2s linear;
			-moz-transition: all 0.2s linear;
			-o-transition: all 0.2s linear;
			transition: all 0.2s linear;
			&:hover{
				color: #ddd;
			}
		}
	}
}

