#portfolios{
   background: #f2f2f2;

  .mix{
    padding: 10px;
  }
  .portfolio-item{
	.shot-item{
		margin: 0px;
	}
  }
}

.controls{
  padding: 0px 0px 20px;

  .active{
    color: $preset !important;
    border-color: $preset;
    background: transparent;
  }
  .btn{
  	text-transform: uppercase;
  	margin: 2px;
  	color: $white;
  }
  &:hover{
  	cursor: pointer;
  }
}

.shot-item{
	margin-right: 15px;
	border-radius: 4px;
	background: $white;
	position: relative;	
	img{
		width: 100%;
	}
	.overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(0, 180, 217, 0.6);
		opacity: 0;
		-webkit-transition:all 0.5s ease-in-out;
		transition:all 0.5s ease-in-out
	}

	&:hover .overlay{
		opacity: 1;
	}
}

.overlay{
	opacity: 0;
	.icons{
		i{
			height: 42px;
			width: 42px;
			line-height: 42px;
			color: $preset;
			left: 50%;
			margin-left: -24px;
			margin-top: -24px;
			top: 50%;
			position: absolute;
			z-index: 2;
			cursor: pointer;
			text-align: center;
			font-size: 20px;
			transition: all 0.5s ease-in-out;
			background: $white;
			border-radius: 4px;
		}
	}
	.link{
		position: absolute;
	    left: 50%;
	    top: 50%;
		transform: translate(-50%, -50%);
	    color: $white;
	}

}


a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover{
	color: $white;
}
